.child-container {
  color: $colorText;
  z-index: 1;
  margin: 100px auto;
  font: $fontNormal;
  width: 40%;
  min-width: 450px;

  h1 {
    font: $fontLarge;
    margin-bottom: 40px;
  }

  .input-wrapper {
    margin-bottom: 50px;
  }

  .input-field {
    display: flex;

    input {
      @include border-cit;

      border-radius: 5px 0px 0px 5px;
      color: $colorText;
    }

    button {
      @include border-cit;

      border-radius: 0px 5px 5px 0px;
      color: #fff;
    }
  }

  .input-textarea {
    @include border-cit;

    border-radius: 5px 0px 0px 5px;
    display: flex;
    width: 100%;
    resize: none;
    color: $colorText;
    outline: none;
    font-size: 16px;
    padding: 8px;
  }

  .current-value {
    @include border-radius;

    background-color: $colorDarkModeBackground;
    color: #fff;
    font: $fontInputAddress;
    padding: 6px;
    margin: 5px auto 8px auto;
    word-wrap: break-word;
  }

  .settings-header {
    margin: 80px auto 35px auto;
  }

  .toggle-settings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -10px;
  }

  .basic-single {
    text-align: left;
    color: $colorDarkModeBackground;
    margin-bottom: 25px;

    svg {
      @include transition;
      color: $colorDarkModeBackground;
    }
  }

  .theme-options {
    text-align: center;
    padding: 10px 15px;
    cursor: pointer;

    img {
      @include border-radius;
      @include border-cit;

      margin: 5px 0;
      width: 180px;
    }
  }
}

.dropdown__menu>div {
  display: flex;
  flex-wrap: wrap;

  >div {
    flex: 50%;
  }
}

.dropdown__single-value {
  display: flex;

  .theme-options {
    padding: 0;
  }

  img {
    display: none;
  }
}

.dropdown__menu-list::-webkit-scrollbar {
  width: 8px;
}

.dropdown__menu-list::-webkit-scrollbar-track {
  background: #fff;
}

.dropdown__menu-list::-webkit-scrollbar-thumb {
  background: $colorScrollbar;

  &:hover {
    background: $colorScrollbarHover;
  }
}

.dropdown__option {
  @include border-radius;

  padding: 0 !important;
}

// DARKMODE
.darkmode .child-container {
  color: #fff;

  .current-value {
    background-color: $colorLightModeBackground;
    color: $colorText;
  }
}

@media only screen and (max-width: 500px) {
  .child-container {
    min-width: 350px;

    .theme-options {
      padding: 5px;

      img {
        width: 125px;
      }
    }
  }

  .dropdown__menu-list::-webkit-scrollbar {
    width: 5px;
  }
}

@media only screen and (max-width: 320px) {
  .child-container {
    min-width: 280px;
  }
}