// BACKGROUND
$colorLightModeBackground: #F6F9FD;
$colorLightModeBackgroundTransparent: rgba(246, 249, 253, 0.9);
$colorDarkModeBackground: #082A38;
$colorDarkModeBackgroundTransparent: rgba(8, 42, 56, 0.9);
$colorInfoBackground: rgba(255, 255, 255, 0.8);

// TEXT
$colorText: #09445A;
$colorDarkModeText: #fff;

// BUTTON
$colorBtn: #E5690C;
$colorBtnHover: #a74c0c;
$colorScrollbar: #888;
$colorScrollbarHover: #555;

// HOVER
$colorHover: #424242;
$colorDarkModeHover: #c7c5c5;

// ERROR
$colorError: #ff0011;
$colorWarning: #f44336;

// SUCCESS
$colorSuccess: #5BB318;