.legend {
  @include border-radius;

  position: absolute;
  top: 20px;
  right: 15px;
  z-index: 999999;
  padding: 8px;
  background: $colorInfoBackground;
}

.legend-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  height: 20px;
}

.legend-img {
  width: 10px;
  height: 10px;
}

.legend-item p {
  font: $fontSmall;
  color: $colorText;
}

.legend-item p::first-letter {
  text-transform: capitalize;
}