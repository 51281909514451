.denm-container {
  flex-shrink: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.denm-container img {
  height: 100px;
  margin-bottom: 10px
}

.denm-container p {
  font: $fontXlarge;
  margin: 0;
  color: $colorDarkModeBackground;
}

// DARKMODE
.darkmode .denm-container p {
  color: $colorDarkModeText;
}