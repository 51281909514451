.sidebar {
  position: absolute;
  left: 0;
  top: 0;
  width: 500px;
  height: 100vh;
  border-radius: 0 5px 5px 0;
  background-color: $colorLightModeBackground;
  overflow-y: auto;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: $colorScrollbar;

    &:hover {
      background: $colorScrollbarHover;
    }
  }
}

.version-tag {
  color: $colorText;
  float: right;
  padding: 6px 24px;
  font-size: x-small;
}

.legal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 15px 15px;

  a {
    @include transition;

    color: $colorText;
    text-decoration: none;
    text-decoration-color: transparent;
    font-size: small;

    &:hover {
      text-decoration: underline;
    }
  }
}

.menu-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 15px;
  left: 15px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 999999;
}

.menu-btn>span,
.menu-btn>span::before,
.menu-btn>span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: $colorText;
  transition-duration: 0.25s;
}

.menu-btn>span::before {
  content: "";
  top: -8px;
}

.menu-btn>span::after {
  content: "";
  top: 8px;
}

#menu-toggle {
  display: none;
}

#menu-toggle:checked~.menu-btn>span {
  transform: rotate(45deg);
}

#menu-toggle:checked~.menu-btn>span::before {
  top: 0;
  transform: rotate(0);
  background: $colorText;
}

#menu-toggle:checked~.menu-btn>span::after {
  top: 0;
  transform: rotate(90deg);
  background: $colorText;
}

// SATELLITE VIEW
.satellite-view .menu-btn {
  background: $colorInfoBackground;
  padding: 0.4rem;
  border-radius: 50%;
  border: 2px solid $colorText;

  span {
    width: 60%;
  }
}

// DARKMODE
.darkmode .menu-btn>span,
.darkmode .menu-btn>span::before,
.darkmode .menu-btn>span::after {
  background-color: #fff;
}

.darkmode .version-tag {
  color: #fff;
}

.darkmode #menu-toggle:checked~.menu-btn>span::before {
  background: #fff;
}

.darkmode #menu-toggle:checked~.menu-btn>span::after {
  background: #fff;
}

.darkmode .sidebar {
  background: $colorDarkModeBackground;
}

.darkmode .version-tag {
  color: #fff;
}

.darkmode .legal a {
  color: #fff;
}

@media only screen and (max-width: 500px) {
  .sidebar {
    width: 100%;
    border-radius: 0;
  }
}