@mixin transition {
  transition: 0.2s ease;
}

@mixin border-radius {
  border-radius: 5px;
}

@mixin border-black {
  border: 1px solid #000;
}

@mixin border-cit {
  border: 1px solid $colorText;
}