.connectivity,
.valid-coords {
  @include border-radius;

  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;

  svg {
    @include transition;

    color: $colorText;
    cursor: pointer;
  }
}

// SATELLITE VIEW
.satellite-view .connectivity svg,
.satellite-view .valid-coords svg {
  background: $colorInfoBackground;
  padding: 0.5rem;
  border-radius: 50%;
  border: 2px solid black;
}

// DARKMODE
.darkmode .connectivity,
.darkmode .valid-coords {
  svg {
    color: #fff;
  }
}