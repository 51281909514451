.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.span-overlay-message {
  @include border-radius;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: $colorWarning;
  padding: 10px;
  z-index: 1;
}

.cit-one-logos {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;

  img {
    width: 50px;
  }
}


.camera-tracking {
  @include border-radius;

  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1;

  svg {
    @include transition;

    color: $colorDarkModeBackground;
    cursor: pointer;
  }
}

.mapboxgl-ctrl-attrib-inner,
.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-bottom-right {
  display: none !important;
}

// SATELLITE VIEW
.satellite-view .camera-tracking svg {
  background: $colorInfoBackground;
  padding: 0.3rem;
  border-radius: 50%;
  border: 2px solid $colorText;
}

.satellite-view .cit-one-logos {
  background: $colorInfoBackground;
  padding: .3rem;
  border-radius: 50%;
  border: 2px solid $colorText;

  img {
    margin-top: 5px;
  }
}

// DARKMODE
.darkmode svg {
  color: #fff;
}