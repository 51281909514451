body {
  font: $fontNormal;
  color: #fff;
  text-align: center;
}

h2 {
  font: $fontHeader;
  text-align: left;
  margin-left: 3px;
}

button {
  @include transition;
  @include border-black;

  margin-left: -1px;
  padding: 8px 8px;
  cursor: pointer;
  color: #fff;
  font: $fontNormal;
  background-color: $colorBtn;

  &:hover {
    background-color: $colorBtnHover;
  }
}

input {
  @include border-black;

  padding-left: 10px;
  font: $fontNormal;
  outline: none;
  width: 100%;
  height: 40px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    appearance: unset;
    -moz-appearance: textfield;
  }
}

.error-msg {
  color: $colorError;
  font: $fontFeedback;
  position: absolute;
  margin: 0 auto auto 3px;
}

.success-msg {
  color: $colorSuccess;
  font: $fontFeedback;
  position: absolute;
  margin: 0 auto auto 3px;
}