.spat-container {
  flex-shrink: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.spat-container img {
  height: 7.5rem;
}

.spat-container p {
  font: $fontXlarge;
  margin: 0;
  color: $colorDarkModeBackground;
}

// DARKMODE
.darkmode .spat-container p {
  color: $colorDarkModeText;
}