.notification-container {
  @include border-radius;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  left: 0;
  right: 0;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  z-index: 999999;
  padding: 20px;
  background: $colorLightModeBackgroundTransparent;
}

// DARKMODE
.darkmode .notification-container {
  background: $colorDarkModeBackgroundTransparent;
}

@media only screen and (max-width: 500px) {
  .notification-container {
    width: 85%;
    top: 50px;
  }
}