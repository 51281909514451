.data-view {
  @include border-radius;

  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-width: 50%;
  max-height: 88%;
  z-index: 1;
  bottom: 20px;
  left: 15px;
  color: $colorText;
  padding: 0 15px;
  text-align: left;
  background: $colorInfoBackground;

  .properties {
    font: $fontNormal;
    font-weight: 550;
    cursor: pointer;
  }
}

.refresh-button {
  @include border-radius;

  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  scale: 0.7;
  top: 15px;
  left: 80px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 999999;

  svg {
    @include transition;

    color: $colorText;
    cursor: pointer;
  }
}

@media only screen and (max-width: 500px) {
  .data-view {
    max-width: 85%;

    .properties {
      font: $fontSmall;
    }
  }
}

// DARKMODE
.darkmode svg {
  color: #fff;
}