$fontFamily: sans-serif;

// LARGE
$fontXlarge: bold 26px/28px $fontFamily;
$fontLarge: bold 22px/24px $fontFamily;
$fontHeader: bold 18px/20px $fontFamily;

// MEDIUM
$fontNormal: normal 16px/18px $fontFamily;
$fontInputAddress: 600 16px/18px $fontFamily;
$fontFeedback: bold 16px/16px $fontFamily;

// SMALL
$fontSmall: normal 14px/16px $fontFamily;